import React from "react";
import { Spinner } from "react-bootstrap";

const Readying = ({}) => {

    return (
        <div style={{ height: '100%', display: 'flex', justifyContent:'center', alignItems:'center' }}>
            <div style={{ display:'flex' }}>
                <Spinner animation="grow" size="sm" style={{ marginRight:'10px'}}/>
                <div>Readying...</div>
            </div>
        </div>
    );
};

export default Readying;