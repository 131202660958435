import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Spinner } from 'react-bootstrap';
import AnalystPlate from './AnalystPlate';
import { motion } from 'framer-motion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import CreateAIMenu from './CreateAIMenu';
import { useFetchManual } from '../../../utils/useFetchManual';

const AISelection = ({ selectAI, aiSelectionMenu, setAiSelectionMenu }) => {
  const [aiSlots, setAiSlots] = useState([]);
  const [showAICreationMenu, setShowAICreationMenu] = useState(false);
  const { data: aisData, loading: aisLoading, error: aisError, fetchData } = useFetchManual();

  useEffect(() => {
    if (aisData && !aisLoading && !aisError) {
      setAiSlots(currentSlots => {
        return aisData.map((item, index) => ({
          ...currentSlots[index],
          ...item
        })).slice(0, 3);
      });
    }
  }, [aisData, aisLoading, aisError]);

  const refreshAIList = () => {
    fetchData('ai/ais/');
  };

  useEffect(() => {
    refreshAIList();
    
  }, [])



  const popoverAISlot = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">AI Analyst Slots</Popover.Header>
      <Popover.Body>
        This is the number of Analysts you have versus the max number you are able to have.
      </Popover.Body>
    </Popover>
  );

  const numberOfAIs = aiSlots.length;

  if (aisLoading) {
    return (
      <div>
        <div style={{ position:'fixed', height:'100vh', width:'100vw', top:'0', left:'0', display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <Spinner  color="primary" size="3rem" />
        </div>
      </div>
    );
  }

  if (aisError){
    return (
      <div>
        Error: {aisError.message}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Container style={{ margin: '2rem 0', maxWidth: '100%'}}>
        <Row className="justify-content-center">
          <Col style={{ textAlign: 'center' }}>
            <h1>Select or Create AI Analyst</h1>
          </Col>
        </Row>
        <Row style={{ gap: '10px', flexDirection: 'column', alignContent:'center'}}>
          {aiSlots.map
          (slot => (
            <Col 
              style={{ maxWidth:'95%', width: '600px' }}
              key={slot.name}
            >
              <AnalystPlate 
                selectAI={selectAI}
                aiAnalyst={slot}
                aiSelectionMenu={aiSelectionMenu}
                setAiSelectionMenu={setAiSelectionMenu}
              />
            </Col>
          ))}
        </Row>
        {numberOfAIs < 3 && !aisLoading ?
          <Row style={{ display: 'flex', justifyContent: 'center', margin: '15px 0' }} >
            <Col xs='auto' >
              <Button className='fw-bold'
                onClick={() => setShowAICreationMenu(true)}
              >
                Create New
              </Button>
            </Col>
          </Row>  
          :
          <></>
        }
          <Row style={{ marginTop:'15px' }}>
            <Col>
              <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverAISlot} delay={{ show: 500, hide: 0 }}>
                <div 
                  className='text-muted fs-4 lh-1 opacity-50 fw-bold' 
                  style={{ textAlign:'center', userSelect: 'none' }}
                >
                  {numberOfAIs}/3
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
      </Container>
      <CreateAIMenu 
        show={showAICreationMenu}
        setShow={setShowAICreationMenu}
        triggerRefreshAIList={refreshAIList}
      />
    </motion.div>
  );
};

export default AISelection;