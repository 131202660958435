import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const LabelPatternChart = ({ data }) => {
  console.log(data);

  const options = {
  chart: {
    backgroundColor: 'var(--si-body-bg)',
    color: 'var(--si-body-color)',
    zoomType: null,
    panning: false,
    marginRight: 0,
    marginLeft: 0,
    zooming: {
      mouseWheel: {
        enabled: false,
      }
    },
  },
  title: {
    text: ``
  },
  credits: { enabled: false },
  legend: {
    enabled: false
  },
  xAxis: {
    categories: Array.from({ length: data.median_series.length }, (_, i) => i - Math.floor(data.median_series.length / 2)),
    title: {
      text: ''
    },
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    gridLineColor: 'var(--si-border-color-translucent)',
    title: {
      text: ''
    },
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    shared: false, // This is to show a shared tooltip for all series.
    valueDecimals: 2, // Round to nearest hundredth
    valueSuffix: '%', // Add percentage sign
    formatter: function () {
      // Optional: You can further customize how the tooltip looks
      return `${this.series.name}: <b>${this.y.toFixed(2)}</b>%`;
    },
  },
  series: [
    {
      name: 'Mean',
      data: data.mean_series,
      color: 'rgb(13, 110, 253)',
      zIndex: 3,
      lineWidth: 2,
      marker: {
        enabled: false,
      }
    },
    {
      name: '+1σ',
      data: data.mean_1sigma_plus,
      color: 'rgba(13, 110, 253, 0.5)', // Consistent color for 1-sigma pair (green with some transparency)
      dashStyle: 'ShortDash',
      zIndex: 2,
      lineWidth: 1,
      marker: {
        enabled: false,
      }
    },
    {
      name: '-1σ',
      data: data.mean_1sigma_minus,
      color: 'rgba(13, 110, 253, 0.5)', // Same color for 1-sigma pair
      dashStyle: 'ShortDash',
      zIndex: 2,
      lineWidth: 1,
      marker: {
        enabled: false,
      }
    },
    {
      name: '+2σ',
      data: data.mean_2sigma_plus,
      color: 'rgba(13, 110, 253, 0.3)', // Consistent color for 2-sigma pair (orange with more transparency)
      dashStyle: 'ShortDot',
      zIndex: 1,
      lineWidth: 1,
      marker: {
        enabled: false,
      }
    },
    {
      name: '+2σ',
      data: data.mean_2sigma_minus,
      color: 'rgba(13, 110, 253, 0.3)', // Same color for 2-sigma pair
      dashStyle: 'ShortDot',
      zIndex: 1,
      lineWidth: 1,
      marker: {
        enabled: false,
      }
    },
    {
      // Shading between mean and 1-sigma lines (upper bound)
      name: 'Shading Mean to 1-Sigma Plus',
      data: data.mean_series.map((value, index) => [value, data.mean_1sigma_plus[index]]),
      type: 'arearange',
      linkedTo: ':previous',
      color: 'rgba(13, 110, 253, 0.85)', // Light green for shading
      fillOpacity: 0.1,
      zIndex: 0,
      marker: {
        enabled: false,
      }
    },
    {
      // Shading between mean and 1-sigma lines (lower bound)
      name: 'Shading Mean to 1-Sigma Minus',
      data: data.mean_series.map((value, index) => [data.mean_1sigma_minus[index], value]),
      type: 'arearange',
      linkedTo: ':previous',
      color: 'rgba(13, 110, 253, 0.85)', // Light green for shading
      fillOpacity: 0.1,
      zIndex: 0,
      marker: {
        enabled: false,
      }
    },
    {
      // Shading between 1-sigma and 2-sigma lines (upper bound)
      name: 'Shading 1-Sigma to 2-Sigma Plus',
      data: data.mean_1sigma_plus.map((value, index) => [value, data.mean_2sigma_plus[index]]),
      type: 'arearange',
      linkedTo: ':previous',
      color: 'rgba(13, 110, 253, 0.6)', // Light orange for shading
      fillOpacity: 0.05,
      zIndex: -1,
      marker: {
        enabled: false,
      }
    },
    {
      // Shading between 1-sigma and 2-sigma lines (lower bound)
      name: 'Shading 1-Sigma to 2-Sigma Minus',
      data: data.mean_1sigma_minus.map((value, index) => [data.mean_2sigma_minus[index], value]),
      type: 'arearange',
      linkedTo: ':previous',
      color: 'rgba(13, 110, 253, 0.6)', // Light orange for shading
      fillOpacity: 0.05,
      zIndex: -1,
      marker: {
        enabled: false,
      }
    }
  ]
};


  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LabelPatternChart;
