import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AnalystPlate = ({ selectAI, aiAnalyst }) => {

  return (
    <div
      className='card-hover clickable bg-repeat-0'
      onClick={() => selectAI(aiAnalyst)}
      style={{ 
        backgroundImage: `url(https://financialmodelingprep.com/image-stock/${aiAnalyst.ticker}.png)`, 
        backgroundSize:'35px 35px', 
        backgroundPosition:'calc(100% - 10px) center',
        minheight:'40px',
        padding: '5px',
        paddingLeft: '10px',
        boxSizing:'border-box',
        border:'1px solid var(--si-border-color)',
        borderRadius:'var(--si-border-radius)'
      }}
    >
      <Row>
        <Col className='fw-bold fs-5 analyst-name'>
          {aiAnalyst.name}
        </Col>
        <Col xs='auto'>
          <Row>
            <Col className='fw-bold fs-5'>{aiAnalyst.ticker}</Col>
            <Col className='bg-repeat-0' style={{ width:'40px'}}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AnalystPlate;
