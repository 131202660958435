import React from "react";
import { Button } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import { useAI } from "../../../../AIContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Ready = ({ }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedAI, setStatusChecking, setAIStatus } = useAI();

  const readyAI = async (ai_id) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://api.tckrai.com',
      },
    });
    try {
      const response = await fetch(`${API_BASE_URL}ai/ready/${ai_id}/`,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      if (response){
        setStatusChecking(true);
        setAIStatus('readying');
      }
    } catch (error) {
      console.error('Failed to start training AI:', error);
    }
  };

    return (
      <Button variant="primary" size='lg' style={{ width: '120px' }} onClick={() => {readyAI(selectedAI.id); setStatusChecking(true);setAIStatus('readying');}}>
        Train
      </Button>
    );
};

export default Ready;