import React from "react";
import { Modal } from "react-bootstrap";
import Assess from "./pipeline/assess/Assess";

const Stats = ({ show, setShow }) => {

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={'xl-down'}
        size={'xl'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            AI Analyst Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Assess />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Stats;