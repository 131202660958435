import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/Button';
import { useFetchManual } from '../../../utils/useFetchManual';

const ManageAI = ({ selectAI, selectedAI, show, setShow }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { data, error, loading, fetchData } = useFetchManual();

  const deleteAI = async (ai_id) => {
    await fetchData(`ai/delete/${ai_id}/`, {
      method: 'DELETE'
    });
    
    if (!error) {
      selectAI(null);
      console.log('AI Analyst deleted successfully:', data);
    } else {
      console.error('Failed to delete AI:', error);
    }
  };

  const handleDelete = () => {
    deleteAI(selectedAI.id);
    setShowConfirm(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Manage AI Analyst
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ width: '100%' }}>
            <div>AI Analyst's Name:</div>
            <h5 style={{ textAlign: 'center' }}>{selectedAI.name}</h5>
          </Row>
          <Row>
            <div>Target Symbol:</div>
            <h5 style={{ textAlign: 'center' }}>{selectedAI.ticker}</h5>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Card style={{ width: '100%', border: 'dashed', color: 'var(--si-danger-border-subtle)' }}>
            <Card.Body>
              <h5 style={{ color: 'var(--si-red)' }}>Danger Zone</h5>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <Button variant='outline-danger' onClick={() => setShowConfirm(true)}>
                    Delete AI Analyst
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this AI Analyst? This cannot be reversed
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete} disabled={loading}>
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageAI;
