import React from "react";
import { useAI } from "../../AIContext";
import InitialTrainingScenarios from "./teaching/InitialTrainingScenarios";
import Working from "./processing/Working";
import Assess from "./assess/Assess";
import RefineView from "./teaching/RefineView";
import Readying from "./processing/Readying";

const Pipeline = ({}) => {
  const { aiStatus } = useAI();

  return (
    <>
      {aiStatus === 'initial' ?
        <InitialTrainingScenarios />
      : aiStatus === 'working' ? 
        <Working />
      : aiStatus === 'assess' ?
        <Assess />
      : aiStatus === 'refining' ?
        <RefineView />
      : aiStatus === 'readying' ?
        <Readying />
      :
       <></>
      }
    </>
  );

};

export default Pipeline;