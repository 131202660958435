import React, { useEffect } from "react";
import { useFetchManual } from "../../../../../utils/useFetchManual";
import { Spinner, Row, Col, ProgressBar, Button } from "react-bootstrap";
import ScenarioChart from "./ScenarioChart";
import { useAuth0 } from "@auth0/auth0-react";
import { useAI } from "../../../AIContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InitialTrainingScenarios = ({}) => {
  const { selectedAI, setAIStatus } = useAI();
  const {data: scenarioData, loading: scenarioLoading, error: scenarioError, fetchData} = useFetchManual()
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchData(`ai/scenario/${selectedAI.id}`);
  }, [selectedAI.id]);

  const refreshScenario = () => {
    fetchData(`ai/scenario/${selectedAI.id}`);
  }

  const handleLabelClick = async (label) => {

    const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tckrai.com',
        },
      });

    const response = await fetch(`${API_BASE_URL}ai/label-data/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        label: label,
        ai_id: selectedAI.id,
        label_timestamp: scenarioData.label_timestamp,
      }),
      withCredentials: true
    });

    if (response.ok) {
      // Reload scenario data after successful submission
      refreshScenario();
      console.log('Label submitted successfully');
    } else {
      console.error('Error submitting label');
    }
  };

  if (scenarioData){
    if (scenarioData.work_status === 'working'){
      setAIStatus('working');
    }
  }

  if (scenarioError) {
    return (
     <div>
        Error
     </div>
    )
  }

  return (
    <>
      {scenarioData && (
        <Row style={{ margin: '0', padding: '0', height:'100%', display:'flex', flexDirection:'column'}}>
          <Row>
            <Col style={{ textAlign:'center', padding:'10px'}}>
              <h6>Initial Training</h6>
            </Col>
          </Row>
          <Row style={{ flex:'1' }}>
            {scenarioLoading ?
              <div style={{ minHeight:'400px' }}>
                <div style={{ 
                    top: '0',
                    left:'0',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '1050'
                }}>
                    <Spinner color="primary" size="3rem" />
                </div>
              </div>
            :
              <ScenarioChart
                scenarioData={scenarioData}
                selectedAI={selectedAI}
                scenarioLoading={scenarioLoading}
                scenarioError={scenarioError}
              />
            }
          </Row>
          <Row>
            <Row style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '10px', padding:'20px'}}>
            {selectedAI.labels.map((label, index) => (
                <Col>
                {scenarioData?.label_session_type === 'initial' ?
                    <ProgressBar now={scenarioData.label_counts[label.id]} max={10} variant='gradient-primary' style={{ height: '4px' }} />
                    
                : 
                    <div></div>
                }
                    
                <Button
                    key={index}
                    onClick={() => {handleLabelClick(label.id); console.log('label clicked!')}}
                    style={{ width: '100%' }}
                    variant='outline-secondary'
                >
                    {label.label}
                </Button>
                </Col>
                
            ))}
            </Row>
          </Row>
        </Row>
      )}
    </>
  )


};

export default InitialTrainingScenarios;