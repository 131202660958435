import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { Modal, Form, Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useFetchManual } from "../../../utils/useFetchManual";
import LabelWindowChart from "./LabelWindowChart"; // Import the chart component
import AsyncSelect from 'react-select/async'; // Import AsyncSelect
import debounce from 'lodash.debounce'; // Import debounce
import { useAuth0 } from '@auth0/auth0-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateAIMenu = ({ show, setShow, triggerRefreshAIList }) => {
  const allowedLookAheadValues = [5, 10, 15, 30, 60]; // Allowed values for lookAhead
  const allowedLookBehindValues = [0, 5, 10, 15, 30]; // Allowed values for lookBehind
  const { data: submitData, error: submitError, loading: submitLoading, fetchData } = useFetchManual();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const initialFormState = { 
    name: '', 
    ticker: null, // Initialize as null to hold the selected ticker object
    labels: ['', ''], 
    lookAheadIndex: 2, // Default to 15
    lookBehindIndex: 3 // Default to 15
  };

  const [aiForm, setAiForm] = useState(initialFormState);
  const [validated, setValidated] = useState(false);
  const [aiSlots, setAiSlots] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAiForm({
      ...aiForm,
      [name]: value,
    });
  };

  // Helper functions to map indices to actual values
  const getLookAheadValue = (index) => allowedLookAheadValues[index];
  const getLookBehindValue = (index) => allowedLookBehindValues[index];

  // Handler for lookBehind slider
  const handleLookBehindChange = useCallback((e) => {
    const invertedIndex = parseInt(e.target.value, 10);
    setAiForm(prevForm => ({
      ...prevForm,
      lookBehindIndex: invertedIndex
    }));
  }, []);
  
  const handleLookAheadChange = useCallback((e) => {
    const index = parseInt(e.target.value, 10);
    setAiForm(prevForm => ({
      ...prevForm,
      lookAheadIndex: index
    }));
  }, []);

  const handleLabelChange = (index, value) => {
    const newLabels = aiForm.labels.slice();
    newLabels[index] = value;
    setAiForm({
      ...aiForm,
      labels: newLabels
    });
  };

  const addLabel = () => {
    setAiForm({
      ...aiForm,
      labels: [...aiForm.labels, '']
    });
  };

  const removeLabel = () => {
    setAiForm({
      ...aiForm,
      labels: aiForm.labels.slice(0, aiForm.labels.length - 1)
    });
  };

  // Function to load ticker options asynchronously
  const loadTickerOptions = async (inputValue, callback) => {
    if (!isAuthenticated) {
      callback([]);
      return;
    }
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tckrai.com',
        },
      });

      const response = await axios.get(`${API_BASE_URL}mdata/tickers/`, {
        params: { q: inputValue },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const options = response.data.map(ticker => ({
        value: ticker.id,
        label: `${ticker.symbol} - ${ticker.company_name}`,
      }));
      callback(options);
    } catch (error) {
      console.error('Error fetching tickers:', error);
      callback([]);
    }
  };

  // Create a debounced version of loadTickerOptions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadTickerOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue) {
        callback([]);
        return;
      }
      loadTickerOptions(inputValue, callback);
    }, 300),
    [] // Dependencies array; empty to ensure it's created only once
  );

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedLoadTickerOptions.cancel();
    };
  }, [debouncedLoadTickerOptions]);

  // Handler for ticker selection
  const handleTickerChange = (selectedOption) => {
    setAiForm({
      ...aiForm,
      ticker: selectedOption, // Store the entire selected option
    });
  };

  const addAI = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false || !aiForm.ticker) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const { name, ticker, labels, lookAheadIndex, lookBehindIndex } = aiForm;
    const newAI = { 
      name, 
      ticker: ticker.value, // Use the selected ticker's ID
      labels, 
      lookAheadValue: getLookAheadValue(lookAheadIndex), 
      lookBehindValue: getLookBehindValue(lookBehindIndex) 
    };

    try {
      fetchData('ai/create/', {
        method: 'POST',
        data: JSON.stringify(newAI)
      });
      setAiSlots([...aiSlots, submitData]);
      setAiForm(initialFormState);
      setTimeout(() => {
        triggerRefreshAIList();
      }, 1000); // 1000 milliseconds = 1 second
      setShow(false);
    } catch (error) {
      console.error('Failed to create AI:', error);
    }
  };

  const popoverLabelHelp = (
    <Popover id="popover-label-help">
      <Popover.Body>
        Your custom labels that your AI analyst will learn to attach to predicted price movements.
      </Popover.Body>
    </Popover>
  );

  const popoverLookAheadHelp = (
    <Popover id="popover-lookahead-help">
      <Popover.Body>
        This is how far ahead your AI analyst is trying to predict the price movement pattern associated with your custom label.
      </Popover.Body>
    </Popover>
  );

  const popoverLookBehindHelp = (
    <Popover id="popover-lookbehind-help">
      <Popover.Body>
        This is how far back your AI analyst looks to analyze past price movements.
      </Popover.Body>
    </Popover>
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false); 
        setAiForm(initialFormState);
        setValidated(false);
      }}
      fullscreen={'xl-down'}
      scrollable={'true'}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg" // Increase modal size to accommodate the chart
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create AI Analyst
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={addAI}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formName">
              <Form.Label style={{ fontSize: '1.2rem' }}>Name</Form.Label>
              <Form.Control 
                type="text" 
                name="name" 
                value={aiForm.name} 
                onChange={handleInputChange} 
                required 
              />
              <Form.Control.Feedback type="invalid">
                Please provide a name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {/* Ticker AsyncSelect */}
            <Form.Group as={Col} controlId="formTicker">
              <Form.Label style={{ fontSize: '1.2rem' }}>Ticker</Form.Label>
              <AsyncSelect
                cacheOptions
                loadOptions={debouncedLoadTickerOptions}
                defaultOptions
                onChange={handleTickerChange}
                value={aiForm.ticker}
                placeholder="Select a ticker"
                isClearable
                styles={{
                  clearIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.5)', // Adjust color based on focus
                    cursor: 'pointer',
                    padding: '8px',
                    '&:hover': {
                      color: 'rgba(255, 255, 255, 0.9)', // Darken on hover
                    },
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.5)', // Adjust color based on focus
                    cursor: 'pointer',
                    padding: '8px',
                    '&:hover': {
                      color: 'rgba(255, 255, 255, 0.9)', // Darken on hover
                    },
                  }),
                
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Subtle separator color
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : 'transparent', // Highlight color on hover
                    color: state.isSelected ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.7)', // Text color when selected
                    cursor: 'pointer',
                    ':active': {
                      backgroundColor: !state.isDisabled
                        ? state.isSelected
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'transparent'
                        : undefined,
                    },
                  }),
                  input: (provided, state) => ({
                    ...provided,
                    color: '',
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: 'rgba(255, 255, 255, 0.7)',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: state.isFocused ? 'rgba(255, 255, 255, 0.28)' : 'rgba(255, 255, 255, 0.18)',
                    boxShadow: state.isFocused ? '0 0 0 1px rgba(255, 255, 255, 0.36)' : 'none', // Override boxShadow to remove blue tint
                    '&:hover': {
                      borderColor: state.isFocused ? 'rgba(255, 255, 255, 0.28)' : 'rgba(255, 255, 255, 0.18)', // Maintain border color on hover
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.02)',
                    color: 'rgba(255, 255, 255, 0.7)',
                    padding: '0.4rem .5rem',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'var(--si-modal-bg)',
                    border: '1px solid rgba(255, 255, 255, 0.18)',
                  }),
                }}
              />
              {validated && !aiForm.ticker && (
                <div style={{ color: 'red', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Please choose a ticker.
                </div>
              )}
            </Form.Group>
          </Row>
          <Form.Label style={{ fontSize: '1.2rem' }}>Label Window</Form.Label>
          <Row className="mb-3">
            {/* Look Behind Slider */}
            <Col>
              <Form.Group as={Col} controlId="formLookBehind">
                <div style={{ display:'flex', gap:'5px'}}>
                  <Form.Label style={{ fontSize: '1rem' }}>Look Behind</Form.Label>
                  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverLookBehindHelp} delay={{ show: 500, hide: 0 }}>
                    <div 
                        className='text-muted fs-5 lh-1 opacity-50 fw-bold' 
                        style={{ display:'flex', justifyContent: 'center', alignItems:'center', userSelect: 'none', marginBottom:'5px' }}
                    >
                        <box-icon name='help-circle' color='var(--si-tertiary-color)' size='16px'></box-icon>
                    </div>
                  </OverlayTrigger>
                </div>
                <div style={{ display: 'flex', justifyContent:'center' }} className="fs-4">{getLookBehindValue(aiForm.lookBehindIndex)}</div>
                <Form.Range 
                  min={0}
                  max={allowedLookBehindValues.length - 1}
                  step={1}
                  value={aiForm.lookBehindIndex} // Use index as value
                  onChange={handleLookBehindChange} 
                />
              </Form.Group>
            </Col>
            {/* Look Ahead Slider */}
            <Col>
              <Form.Group as={Col} controlId="formLookAhead">
                <div style={{ display:'flex', gap:'5px'}}>
                  <Form.Label style={{ fontSize: '1rem' }}>Look Ahead</Form.Label>
                  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverLookAheadHelp} delay={{ show: 500, hide: 0 }}>
                    <div 
                        className='text-muted fs-5 lh-1 opacity-50 fw-bold' 
                        style={{ display:'flex', justifyContent: 'center', alignItems:'center', userSelect: 'none', marginBottom:'5px' }}
                    >
                        <box-icon name='help-circle' color='var(--si-tertiary-color)' size='16px'></box-icon>
                    </div>
                  </OverlayTrigger>
                </div>
                <div style={{ display: 'flex', justifyContent:'center' }} className="fs-4">{getLookAheadValue(aiForm.lookAheadIndex)}</div>
                <Form.Range 
                  min={0}
                  max={allowedLookAheadValues.length - 1}
                  step={1}
                  value={aiForm.lookAheadIndex} 
                  onChange={handleLookAheadChange} 
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Candlestick Chart */}
          <Row className="mb-4">
            <Col>
              <LabelWindowChart 
                lookBehind={getLookBehindValue(aiForm.lookBehindIndex)} 
                lookAhead={getLookAheadValue(aiForm.lookAheadIndex)} 
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formLabels">
              <div style={{ display:'flex', gap:'5px'}}>
                <Form.Label style={{ fontSize: '1.2rem' }}>Labels</Form.Label>
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverLabelHelp} delay={{ show: 500, hide: 0 }}>
                  <div 
                    className='text-muted fs-5 lh-1 opacity-50 fw-bold' 
                    style={{ display:'flex', justifyContent: 'center', alignItems:'center', userSelect: 'none', marginBottom:'5px' }}
                  >
                    <box-icon name='help-circle' color='var(--si-tertiary-color)' size='16px'></box-icon>
                  </div>
                </OverlayTrigger>
              </div>
              {aiForm.labels.map((label, index) => (
                <Form.Control 
                  key={index} 
                  type="text" 
                  value={label} 
                  onChange={(e) => handleLabelChange(index, e.target.value)} 
                  className="mb-2" 
                  required 
                />
              ))}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {aiForm.labels.length < 7 && (
                  <Button variant="secondary" onClick={addLabel} className="me-2">Add Label</Button>
                )}
                {aiForm.labels.length > 2 && (
                  <Button variant="outline-danger" onClick={removeLabel}>Remove Label</Button>
                )}
              </div>
              <div className="form-text"></div>
              {validated && aiForm.labels.length < 2 && (
                <div style={{ color: 'red', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Please provide at least two labels.
                </div>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'right' }}>
              <Button variant="primary" type="submit">Create AI</Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAIMenu;
