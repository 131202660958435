import React, { useState, useEffect, useCallback } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import './AnalysisChart.css';
import { format } from 'date-fns';
import { Row } from 'react-bootstrap';

const AnalysisChart = ({ selectedAI, analysisChartData }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [currentDataPoint, setCurrentDataPoint] = useState(null);
  const [latestDataPoint, setLatestDataPoint] = useState(null);

  // Initialize currentDataPoint with the latest data point
  useEffect(() => {
    if (analysisChartData && analysisChartData.length > 0) {
      const latest = analysisChartData[analysisChartData.length - 1];
      setLatestDataPoint(latest);
      setCurrentDataPoint(latest);
    }
  }, [analysisChartData]);

  const createOptions = useCallback((data) => {
    return {
      time: {
        useUTC: false
      },
      chart: {
        backgroundColor: 'var(--si-body-bg)',
        color: 'var(--si-body-color)',
        zoomType: null,
        panning: false,
        marginRight: 0,
        marginLeft: 0,
        zooming: {
          mouseWheel: {
            enabled: false,
          }
        },
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      title: {
        text: '',
      },
      yAxis: {
        gridLineColor: 'var(--si-border-color-translucent)',
        labels: {
          x: 0,
          style: {
            color: 'var(--si-body-color)',
            fontSize: '10px',
          },
        },
      },
      xAxis: {
        overscroll: '50px',
        labels: {
          style: {
            color: 'var(--si-body-color)',
          },
        },
      },
      tooltip: {
        enabled: true, // Enable the tooltip for lookforward predictions
        formatter: function () {
          if (this.point.mean !== undefined) {
            return `Predicted Value: <b>${this.point.mean.toFixed(2)}</b>`;
          }
          return false;
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: function () {
                setCurrentDataPoint(this);
              },
              mouseOut: function () {
                setCurrentDataPoint(latestDataPoint);
              }
            }
          },
          states: {
            inactive: {
              enabled: false
            }
          }
        }
      },
      series: [
        {
          type: 'candlestick',
          name: selectedAI.ticker,
          data: data.filter(item => item.open !== undefined).map(item => ({
            x: item.x,
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            volume: item.volume,
            label: item.label,
          })),
          color: '#eb1515',
          upColor: 'transparent',
          lineColor: '#eb1515',
          upLineColor: '#1a9a49',
          lineWidth: 1,
          dataGrouping: {
            enabled: false,
          },
        },
        {
          type: 'line',
          name: 'Lookforward Mean',
          data: (data || []).filter(item => item.mean !== undefined).map(item => ({
            x: item.x,
            y: item.mean
          })),
          color: 'rgba(13, 161, 255, 0.9)',
          dashStyle: 'Dash',
        },
        {
          type: 'arearange',
          name: 'Lookforward Mean ± 1-Sigma',
          data: (data || []).filter(item => item.mean_1sigma_plus !== undefined && item.mean_1sigma_minus !== undefined).map(item => ({
            x: item.x,
            low: item.mean_1sigma_minus,
            high: item.mean_1sigma_plus
          })),
          color: 'rgba(13, 161, 255, 0.6)',
          fillOpacity: 0.2,
        },
        {
          type: 'arearange',
          name: 'Lookforward Mean ± 2-Sigma',
          data: (data || []).filter(item => item.mean_2sigma_plus !== undefined && item.mean_2sigma_minus !== undefined).map(item => ({
            x: item.x,
            low: item.mean_2sigma_minus,
            high: item.mean_2sigma_plus
          })),
          color: 'rgba(13, 161, 255, 0.4)',
          fillOpacity: 0.1,
        }
      ],
    };
  }, [selectedAI, latestDataPoint]);

  useEffect(() => {
    const data = (analysisChartData || []).map(item => ({
      x: item.x,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      volume: item.volume,
      label: item.label,
      mean: item.mean,
      mean_1sigma_plus: item.mean_1sigma_plus,
      mean_1sigma_minus: item.mean_1sigma_minus,
      mean_2sigma_plus: item.mean_2sigma_plus,
      mean_2sigma_minus: item.mean_2sigma_minus,
    }));

    setChartOptions(createOptions(data));
  }, [analysisChartData, createOptions]);

  return (
    <div className="analysis-chart-container" style={{ boxSizing: 'border-box'}}>
      {currentDataPoint && (
        <Row>
          <div className="chart-readout">
            <span className="readout-item">
              <div style={{ color:'var(--si-tertiary-color)' }}>D: </div> {format(new Date(currentDataPoint.x), 'M/d hh:mm a')}
            </span>
            {currentDataPoint.open && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>O: </div>{currentDataPoint.open.toFixed(2)}</span>}
            {currentDataPoint.high && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>H: </div>{currentDataPoint.high.toFixed(2)}</span>}
            {currentDataPoint.low && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>L: </div>{currentDataPoint.low.toFixed(2)}</span>}
            {currentDataPoint.close && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>C: </div>{currentDataPoint.close.toFixed(2)}</span>}
            {currentDataPoint.volume && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>V: </div>{currentDataPoint.volume.toLocaleString()}</span>}
            {currentDataPoint.label && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>P: </div><strong>{currentDataPoint.label}</strong></span>}
          </div>
        </Row>
      )}

      {chartOptions && (
        <Row style={{ height: 'calc(100% - 36px)'}}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={chartOptions}
          />
        </Row>
      )}
    </div>
  );
};

export default AnalysisChart;
