import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Annotations from "highcharts/modules/annotations";

// Initialize the Annotations module
Annotations(Highcharts);

// Function to generate a random candlestick data point based on the last close
const generateRandomPoint = (lastClose, timestamp) => {
  const open = lastClose;
  const close = open + (Math.random() - 0.5) * 30;
  const high = Math.max(open, close) + Math.random() * 15;
  const low = Math.min(open, close) - Math.random() * 15;

  return [timestamp, open, high, low, close];
};

const LabelWindowChart = ({ lookBehind, lookAhead }) => {
  const chartComponentRef = useRef(null);
  const annotationIdRef = useRef("centralAnnotation");
  const intervalRef = useRef(null);

  // Number of extra candlesticks to add to the left
  const extraLeft = 5;

  const extraRight = 5

  // Total data points needed: extraLeft + lookBehind + central + lookAhead
  const totalPoints = extraLeft + lookBehind + 1 + lookAhead + extraRight;

  // Initialize data on first render and when totalPoints changes
  const initialData = useRef([]);
  const initialTimestamp = useRef(Date.now());

  const generateInitialData = () => {
    const data = [];
    let lastClose = 100; // Starting price
    let timestamp = initialTimestamp.current;

    for (let i = 0; i < totalPoints; i++) {
      const point = generateRandomPoint(lastClose, timestamp);
      data.push(point);
      lastClose = point[4]; // Update lastClose
      timestamp += 24 * 3600 * 1000; // Increment by one day
    }

    initialData.current = data;
  };

  useEffect(() => {
    generateInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPoints]);

  // Define Highcharts options
  const options = {
    chart: {
      type: "candlestick",
      backgroundColor: "transparent",
      height: 300,
      // Disable all interactions
      panning: false,
      zooming: false,
      events: {
        load: function () {
          const chart = this;
          const series = chart.series[0];
          const initialDataset = initialData.current;

          // Add all initial data points
          series.setData(initialDataset, true, true);

          // Set initial central point
          const centralIndex = extraLeft + lookBehind;
          const centralPoint = series.data[centralIndex];

          if (centralPoint) {
            // Add initial annotation
            addAnnotation(chart, centralPoint);

            // Add initial plot bands
            addPlotBands(chart, series, centralIndex);
          }

          // Start live updates
          startLiveUpdates(chart, series);
        },
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      type: "datetime",
      plotBands: [], // Will be added dynamically
      labels: {
        enabled: false, // Disable X-axis labels
      },
      lineWidth: 0, // Remove the x-axis line
      tickLength: 0,
      // Optional: Add grid lines or other xAxis configurations
    },
    yAxis: {
      visible: false, // Keep hidden or set to true for debugging
      opposite: false,
    },
    legend: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: "Random Data",
        data: [], // Will be set on load
        tooltip: {
          enabled: false,
        },
        color: "#eb1515",
        upColor: "transparent",
        lineColor: "#eb1515",
        upLineColor: "#1a9a49",
        lineWidth: 1,
        dataGrouping: {
          enabled: false, // Disable data grouping (no aggregation)
        },
      },
    ],
    annotations: [], // Will be added dynamically
  };

  // Function to add/update annotations
  const addAnnotation = (chart, point) => {
    if (!point) return;

    // Remove existing annotation if any
    if (chart.annotations.length > 0) {
      chart.removeAnnotation(annotationIdRef.current);
    }

    // Define the new annotation
    const annotation = {
      id: annotationIdRef.current,
      labels: [
        {
          point: {
            x: point.x,
            y: point.high,
            xAxis: 0,
            yAxis: 0,
          },
          text: "Label",
          borderColor: "rgba(255,255,255,0.8)",
          backgroundColor: "rgba(255,255,255,0.8)",
          style: {
            color: "#000000", // Adjusted for better visibility
            fontWeight: "bold",
          },
          align: "center",
          verticalAlign: "bottom",
        },
      ],
      shapes: [
        {
          type: "path",
          points: [
            {
              x: point.x,
              y: point.low,
              xAxis: 0,
              yAxis: 0,
            },
            {
              x: point.x,
              y: point.high + 10,
              xAxis: 0,
              yAxis: 0,
            },
          ],
          stroke: "var(--si-primary)", // Adjusted for better visibility
          strokeWidth: 2,
        },
      ],
    };

    // Add the annotation to the chart
    chart.addAnnotation(annotation);
  };

  // Function to add/update plot bands
  const addPlotBands = (chart, series, centralIndex) => {
    if (!series || !series.data) return;
  
    // Ensure centralIndex is within bounds
    if (
      centralIndex - lookBehind < 0 ||
      centralIndex + lookAhead >= series.data.length
    ) {
      console.warn("centralIndex out of bounds for plot bands.");
      return;
    }
  
    const lookBehindStart = series.data[centralIndex - lookBehind].x;
    // const lookBehindEnd = series.data[centralIndex].x;
  
    const lookAheadStart = series.data[centralIndex].x;
    const lookAheadEnd = series.data[centralIndex + lookAhead].x;
  
    // Extend the plot band to cover the entire most recent candlestick
    const nextPoint = series.data[centralIndex + lookAhead + 1];
    const extendedLookAheadEnd = nextPoint ? nextPoint.x : lookAheadEnd + (24 * 3600 * 1000); // If there's no next point, add a full day
  
    // Remove existing plot bands
    chart.xAxis[0].removePlotBand("lookBehind");
    chart.xAxis[0].removePlotBand("lookAhead");
  
    // Add new plot bands
    // chart.xAxis[0].addPlotBand({
    //   id: "lookBehind",
    //   from: lookBehindStart,
    //   to: lookBehindEnd,
    //   color: "rgba(255, 255, 255, 0.05)",
    //   label: {
    //     text: "LookBehind Window",
    //     style: {
    //       color: "#606060",
    //     },
    //   },
    // });
  
    chart.xAxis[0].addPlotBand({
      id: "lookAhead",
      from: lookAheadStart + (24 * 3600 * 500),
      to: extendedLookAheadEnd + (24 * 3600 * 5000), // Extend to the entire candlestick
      color: "rgba(11, 15, 25, 0.70)",
      label: {
        text: "",
        style: {
          color: "#606060",
        },
      },
      zIndex:4,
    });
    chart.xAxis[0].addPlotBand({
        id: "lookAhead",
        from: lookBehindStart - (24 * 3600 * 500),
        to: extendedLookAheadEnd - (24 * 3600 * 500), // Extend to the entire candlestick
        color: "rgba(255, 255, 255, 0.00)",
        borderColor: "rgba(255, 255, 255, 0.50)",
        borderWidth:1,
        label: {
          text: "Label Window",
          style: {
            color: "#606060",
            fontSize: "1.2rem",
          },
          y: 25,
        },
        zIndex:5,
      });
    
  };

  

  // Function to start live updates
  const startLiveUpdates = (chart, series) => {
    if (!chart || !series) return;

    intervalRef.current = setInterval(() => {
      const seriesData = series.data;
      const lastPoint = seriesData[seriesData.length - 1];

      if (!lastPoint) {
        console.warn("No last point available for generating new data.");
        return;
      }

      const lastClose = lastPoint.close; // Use 'close' from the last candlestick
      const lastTimestamp = lastPoint.x;

      // Generate a new timestamp (e.g., one day after the last)
      const newTimestamp = lastTimestamp + 24 * 3600 * 1000;

      const newPoint = generateRandomPoint(lastClose, newTimestamp);

      // Add the new point and remove the first one to maintain totalPoints
      series.addPoint(newPoint, true, true);

      // Calculate new central index
      const centralIndex = extraLeft + lookBehind;

      // Ensure centralIndex is within bounds
      if (
        centralIndex - lookBehind < 0 ||
        centralIndex + lookAhead >= series.data.length
      ) {
        console.warn("centralIndex out of bounds after adding new point.");
        return;
      }

      const centralPoint = series.data[centralIndex];

      // Update annotations
      addAnnotation(chart, centralPoint);

      // Update plot bands
      addPlotBands(chart, series, centralIndex);
    }, 1500); // Update every second (adjust as needed)
  };

  // Function to reset the chart when lookBehind or lookAhead changes
  const resetChart = () => {
    const chart = chartComponentRef.current?.chart;
    if (!chart) return;

    // Clear existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Generate new initial data
    generateInitialData();

    const series = chart.series[0];
    const newData = initialData.current;

    // Set new data to the series
    series.setData(newData, true, true);

    // Remove existing annotations
    chart.annotations.forEach((annotation) => {
      chart.removeAnnotation(annotation.options.id);
    });

    // Remove existing plot bands
    chart.xAxis[0].removePlotBand("lookBehind");
    chart.xAxis[0].removePlotBand("lookAhead");

    // Set new central index
    const centralIndex = extraLeft + lookBehind;
    const centralPoint = series.data[centralIndex];

    if (centralPoint) {
      // Add new annotation
      addAnnotation(chart, centralPoint);

      // Add new plot bands
      addPlotBands(chart, series, centralIndex);
    }

    // Restart live updates
    startLiveUpdates(chart, series);
  };

  // Watch for changes in lookBehind and lookAhead to reset the chart
  useEffect(() => {
    console.log('reset triggered')
    resetChart();

    // Cleanup function to clear interval when component unmounts or before resetting
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookBehind, lookAhead]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
      ref={chartComponentRef}
    />
  );
};

export default React.memo(LabelWindowChart, (prevProps, nextProps) => {
    return prevProps.lookBehind === nextProps.lookBehind && prevProps.lookAhead === nextProps.lookAhead;
  });