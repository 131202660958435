import { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useFetchManual = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Store a reference to the current cancel token to cancel any ongoing request.
  const cancelTokenRef = useRef(null);

  const fetchData = async (endpoint, options = {}) => {
    if (!isAuthenticated) return;
  
    setLoading(true);
  
    // If there's an ongoing request, cancel it
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation canceled due to new request.');
    }
  
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;
  
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tckrai.com',
        },
      });
  
      const defaultOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        cancelToken: cancelTokenSource.token, // Attach the cancel token to the request
      };
  
      // Construct the full URL
      const url = `${API_BASE_URL}${endpoint}`;
      const requestOptions = { ...defaultOptions, ...options };
  
      const response = await axios(url, requestOptions);
      setData(response.data);
      setError(null);
      return response.data; // Return response data here
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled', err.message);
      } else {
        console.error('Error fetching data:', err);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };
  

  return { data, error, loading, fetchData };
};
