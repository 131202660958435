import React from "react";
import { Button } from "react-bootstrap";
import { useAI } from "../../../../AIContext";

const Refine = ({}) => {
    const { setAIStatus } = useAI();


    return (
        <Button size="lg" onClick={() => {setAIStatus('refining')}}>
            Refine
        </Button>
    );
};

export default Refine;