import React, { useEffect, useRef } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import './AnalystFeed.css'; // Import CSS for custom fade effect
import { format } from 'date-fns';

const AnalystFeed = ({ analysisChartData }) => {
  const feedTopRef = useRef(null);

  // Scroll to the top whenever new entries are added
  useEffect(() => {
    if (feedTopRef.current) {
      feedTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [analysisChartData]);

  return (
    <div style={{ position: 'relative' }}>
        <div className="fade-top" />
        <div className="analyst-feed-container">
        {analysisChartData && (
            <ListGroup className="feed-list">
                {/* Reference for scrolling to the top */}
                <div ref={feedTopRef} />
                {analysisChartData.slice().reverse().map((entry, index) => (
                  <>
                   {entry.close && (
                    <ListGroup.Item key={index} className="feed-item">
                      <Row className="p-0 m-0">
                          <Col>
                              <div>{format(new Date(entry.x), 'hh:mm a')}</div>
                          </Col>
                          <Col style={{ textAlign: 'center' }}>
                              <div><weak style={{ fontSize:'0.7rem', color:'var(--si-tertiary-color)' }}>$</weak>{entry.close}</div>
                          </Col>
                          <Col style={{ textAlign:'right' }}>
                              <div><weak style={{ fontSize:'0.7rem', color:'var(--si-tertiary-color)' }}>v </weak>{entry.volume}</div>
                          </Col>
                      </Row>
                      <Row className="p-0 m-0">
                          <div><weak style={{ fontSize:'0.8rem', color:'var(--si-tertiary-color)' }}>Label: </weak><strong>{entry.label}</strong></div>
                      </Row>
                    </ListGroup.Item>
                   )}
                  </>
                  
                ))}
            </ListGroup>
        )}
        </div>
    </div>
  );
};

export default AnalystFeed;
