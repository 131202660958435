import React from "react";
import OffCanvasNav from './OffCanvasNav';
import { Row, Col } from 'react-bootstrap';
import MainLogo from "./MainLogo";
import './TopBar.css';

const TopBar = () => {

    return (
        <Row className='m-0 p-0'>
          <Col xs={'auto'}>
            <OffCanvasNav />
          </Col>
          <Col style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
            <div className="disclaimer-notice" style={{ textAlign: "center", width:'100%' }} >
              Trading is risky and many traders lose money. AI can make mistakes.<br className="disclaimer-linebreak" /> 
              <a href="https://tckrai.com/disclaimer" target="_blank" className="clickable" style={{ marginLeft: '5px', textDecoration: "none", color:'var(--si-primary)' }}>See our full disclaimer.</a>
            </div>
          </Col>
          <Col xs={'auto'} style={{ display:'flex', alignItems:'center'}}>
            <h6 style={{ margin: '0', letterSpacing: '1px'}}>tckrAI</h6>
            <MainLogo style={{ height: '20px', marginLeft:'3px'}} />
          </Col>
        </Row>
    );
};

export default TopBar;