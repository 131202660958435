import React, { useState, useEffect } from 'react';
import AISelection from './selection/AISelection';
import AnalystView from './view/AnalystView';
import { AIProvider } from './AIContext';

const AnalystDashboard = () => {
  const [selectedAI, setSelectedAI] = useState({});
  const [aiSelectionMenu, setAiSelectionMenu] = useState(false);
  

  const selectAI = (ai) => {
    if (selectedAI !== ai){
      setSelectedAI(ai);
    };
    if (aiSelectionMenu && selectedAI){
      setAiSelectionMenu(false);
    };
  };

  useEffect(() => {
    if (!selectedAI.id){
      setAiSelectionMenu(true);
    }
  }, []);

  return (
    <>
      {aiSelectionMenu || !selectedAI ? 
          <AISelection 
            selectAI={selectAI}
            aiSelectionMenu={aiSelectionMenu}
            setAiSelectionMenu={setAiSelectionMenu}
          />
        : 
          <AIProvider selectedAI={selectedAI}>
            <AnalystView 
              selectAI={selectAI}
              setAiSelectionMenu={setAiSelectionMenu}
            />
          </AIProvider>
      }
    </>
  );
};

export default AnalystDashboard;
