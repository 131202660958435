import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AnalystPlate from "../selection/AnalystPlate";
import ManageAI from "./ManageAI";
import './AnalystView.css';
import { useAI } from "../AIContext";
import LiveView from "./live/LiveView";
import Pipeline from "./pipeline/Pipeline";
import Stats from "./Details";

const AnalystView = ({ selectAI, setAiSelectionMenu }) => {
  const { selectedAI, aiStatus } = useAI();
  const [statsView, setStatsView] = useState(false);
  const [manageAIMenuShow, setManageAIMenuShow] = useState(false);
  
  return (
      <div>
        <Row>
          <Col>
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <div className="av-control-panel-grid">
                  <div className="select-anaylst grid-item" onClick={() => setAiSelectionMenu(true)} >
                    <AnalystPlate 
                      selectAI={selectAI}
                      aiAnalyst={selectedAI}
                    />
                  </div>
                  <div className="manage-analyst grid-item">
                    <div className='clickable card-hover primary-highlight control-option' 
                      onClick={() => setManageAIMenuShow(true)}>
                      <box-icon color='var(--si-body-color)' type='solid' name='cog'></box-icon>
                    </div>
                  </div>
                  
                  <div className="stats-analyst grid-item">
                  {aiStatus === 'ready' && (
                    <div className='clickable card-hover primary-highlight control-option' 
                      onClick={() => setStatsView(true)}>
                      <box-icon name='book-reader' color='var(--si-body-color)'></box-icon>
                    </div>
                  )}
                    
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="analyst-interaction-area">
          {aiStatus === 'ready' ?
            <LiveView />
          :
            <Pipeline />
          }
        </Row>
        <Stats 
          show={statsView}
          setShow={setStatsView}
        />
        <ManageAI
          selectAI={selectAI} 
          show={manageAIMenuShow}
          setShow={setManageAIMenuShow}
          selectedAI={selectedAI}
        />
      </div>
  );
};

export default AnalystView;