import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsDragPanes from 'highcharts/modules/drag-panes';
import StockTools from 'highcharts/modules/stock-tools';
import HighchartsAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';


HighchartsAnnotations(Highcharts);
StockTools(Highcharts);
HighchartsDragPanes(Highcharts);
HighchartsAnnotationsAdvanced(Highcharts);

const ScenarioChart = ({ selectedAI, scenarioData, scenarioLoading, scenarioError }) => {
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (scenarioData) {
      const { ohlcv_data, label_timestamp } = scenarioData;

      if (!ohlcv_data) {
        return;
      }
      

      const formattedData = ohlcv_data.map(point => [
        new Date(point.timestamp).getTime(),
        Number(point.open),
        Number(point.high),
        Number(point.low),
        Number(point.close),
        Number(point.volume)
      ]);

      const labelTimestamp = new Date(label_timestamp).getTime();
      // const closePrice = formattedData.find(d => d[0] === labelTimestamp)?.[4]; // Assuming 4th index is close price


      // Identify the beginning of each new trade day and calculate midpoints
      const newDayTimestamps = [];
      const midpointTimestamps = [];

      for (let i = 1; i < formattedData.length; i++) {
        const prevDate = new Date(formattedData[i - 1][0]);
        const currentDate = new Date(formattedData[i][0]);

        if (prevDate.getDate() !== currentDate.getDate()) {
          newDayTimestamps.push(currentDate.getTime());

          // Calculate the midpoint between the previous and current day
          const midpoint = (currentDate.getTime() + prevDate.getTime()) / 2;
          midpointTimestamps.push(midpoint);
        }
      }

      setChartOptions({
        chart: {
          backgroundColor: 'var(--si-body-bg)',
          color: 'var(--si-body-color)',
          zooming: {
            mouseWheel: {
              enabled: false,
            }
          },
          zoomType: null, // Disable zoom
          panning: false, // Disable panning
          marginRight: 30,
          marginLeft: 30,
        },
        rangeSelector: { enabled: false }, // Disable range selector
        navigator: { enabled: false }, // Disable navigator
        scrollbar: { enabled: false }, // Disable scrollbar
        credits: { enabled: false }, // Disable credits
        exporting: { enabled: false }, // Disable exporting
        stockTools: {
          gui: {
            buttons: ['measure', 'lines'],
            enabled: false,
          }
        },
        series: [{
          type: 'candlestick',
          name: selectedAI.ticker,
          data: formattedData,
          tooltip: {
            valueDecimals: 2
          },
          color: '#eb1515',
          upColor: 'transparent',
          lineColor: '#eb1515',
          upLineColor: '#1a9a49',
          lineWidth: 1,
          dataGrouping: {
            enabled: false, // Disable data grouping (no aggregation)
          },
        }],
        yAxis: {
          title: {
            style: {
              color: 'var(--si-body-color)'
            },
          },
          labels: {
            x: 30,
            style: {
              color: 'var(--si-body-color)'
            },
          },
          gridLineWidth: 0,
          floor: 0,
        },
        xAxis: {
          minorTickLength: 0,
          tickLength: 0,
          title: {
            style: {
              color: 'var(--si-body-color)'
            },
          },
          labels: {
            style: {
              color: 'var(--si-body-color)'
            },
          },
          type: 'datetime',
          ordinal: true,
          plotLines: [
            {
              color: 'purple',
              dashStyle: 'dash',
              value: labelTimestamp,
              width: 2,
              label: {
                text: 'Label this',
                align: 'left',
                style: {
                  color: 'purple',
                  fontSize: '1.1rem'
                }
              }
            },
            ...midpointTimestamps.map(midpoint => ({
              color: 'yellow',
              dashStyle: 'dash',
              value: midpoint,
              width: 1,
            }))
          ]
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
              radius: 3
            }
          },
        }
      });
    }
  }, [scenarioData, selectedAI.ticker]);

  if (scenarioLoading) return <p>Loading...</p>;
  if (scenarioError) return <p>Error loading data</p>;

  return (
    <Row>
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={chartOptions}
        />
      )}
    </Row>
  );
};

export default ScenarioChart;
