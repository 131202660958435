import React, { useState, useCallback, useEffect, createContext, useContext } from "react";
import { useFetchManual } from "../../utils/useFetchManual";

// Create a Context for the AI training
const AIContext = createContext();

// Custom hook to use AI context in child components
export const useAI = () => useContext(AIContext);

export const AIProvider = ({ children, selectedAI }) => {
  const [aiStatus, setAIStatus] = useState('');
  const [statusChecking, setStatusChecking] = useState(false);
  const { fetchData } = useFetchManual();

  const checkStatus = useCallback(async (ai_id) => {
    if (aiStatus !== 'working' && aiStatus !== 'readying') return;

    try {
      const response = await fetchData(`ai/status/${ai_id}/`);
      if (response && response.status) {
        const newStatus = response.status;
        setAIStatus(newStatus);
        if (newStatus !== 'working' && newStatus !== 'readying') {
            setStatusChecking(false);
        }
      }
    } catch (error) {
      console.error('Failed to check AI status:', error);
    }
  }, [aiStatus, fetchData]);

  useEffect(() => {
    if (selectedAI.status) {
        setAIStatus(selectedAI.status);
    }
  }, [selectedAI]);

  useEffect(() => {
    if (aiStatus === 'working' || aiStatus === 'readying') {
      const interval = setInterval(() => {
        checkStatus(selectedAI.id);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [aiStatus, selectedAI.id, checkStatus]);

  return (
    <AIContext.Provider value={{
      selectedAI,
      aiStatus,
      setAIStatus,
      statusChecking,
      setStatusChecking,
      checkStatus,
    }}>
      {children}
    </AIContext.Provider>
  );
};