import React, { useEffect } from "react";
import { useFetchManual } from "../../../../utils/useFetchManual";
import AnalysisChart from "./chart/AnalysisChart";
import AnalystFeed from "./feed/AnalystFeed";
import { useAI } from "../../AIContext";
import { Col } from "react-bootstrap";

const LiveView = ({}) => {
    const { selectedAI } = useAI();
    const { data: analysisChartData, loading: analysisChartDataLoading, error: analysisChartDataError, fetchData } = useFetchManual();

    // Trigger the fetch once on component load
    useEffect(() => {
        fetchData(`mdata/analysis-chart-data/${selectedAI.id}`);
    }, [selectedAI.id]); // Only re-run if selectedAI.id changes

    // Trigger the fetch every 30 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
        fetchData(`mdata/analysis-chart-data/${selectedAI.id}`);
        }, 30000); // 30000 ms = 30 seconds

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [selectedAI.id]); // Re-create interval if selectedAI.id changes

    return (
        <>
          <Col lg={8}>
            <AnalysisChart 
              selectedAI={selectedAI}
              analysisChartData={analysisChartData}
            />
          </Col>
          <Col lg={4}>
            <AnalystFeed analysisChartData={analysisChartData} />
          </Col>
        </>
    );

};

export default LiveView;