import React from "react";
import { useFetch } from "../../../../../utils/useFetch";
import { useAI } from "../../../AIContext";
import LabelPatternChart from "./LabelPatternChart";
import { Row, Col } from "react-bootstrap";
import Refine from "./buttons/Refine";
import Ready from "./buttons/Ready";

const Assess = ({}) => {
  const { selectedAI } = useAI();
  const { data, error, loading } = useFetch(`ai/label-patterns/${selectedAI.id}/`);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <Row style={{ height:'100%', display:'flex', flexDirection:'column' }}>
      <Row style={{ flex: '1', display:'flex', justifyContent:'center', alignItems:'center'}}>
        {data && Object.keys(data).map((label) => (
          <Col key={label} style={{ marginBottom: '' }}>
            <Row>
              <Col>
                {label}
              </Col>
            </Row>
              <LabelPatternChart data={data[label]} label={label} />
          </Col>
        ))}
      </Row>
      <Row style={{ margin: '20px 10px'}}>
        <Col xs={'auto'}>
          <Refine />
        </Col>
        <Col></Col>
        <Col xs={'auto'}>
          <Ready />
        </Col>
      </Row>
    </Row>        
  );
};

export default Assess;